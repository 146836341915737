import ServerProxy from "../../../../utils/serverproxy.js";
import { niceDate, dateInPast, now } from "../../../../utils/dateutils.js";
import { emit } from "../../../../utils/events.js";
class {
    onCreate() {
        this.state = {
            data: {},
            disabled: [],
            loadingData: false,
            entries: []
        };

        // Stores a reference to the timeEntryPopout component.
        // Populated on mount.
        this.timeEntryPopout = null;

        this.more = true;
        this.last = null;

        // Fetch the first n days of time entries.
        this.getEntries();
    }

    getEntries() {
        if(!this.more) {
            return;
        }

        ServerProxy.getPaginatedEntries(10, this.last, (result) => {
            this.state.entries = this.state.entries.concat(result.entries);
            this.last = result.last;
            this.more = result.more;
            this.state.loadingData = false;
        });
    }

    /**
    * Refresh time entries for a day given the start time in milliseconds of a time entry.
    */
    refresh(milliseconds) {
        let date = new Date(milliseconds);
        date.setMilliseconds(0);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setDate(date.getDate() + 1);
        ServerProxy.getPaginatedEntries(1, date, (result) => {
            // If there are no entries for this day don't do anything.
            if(result.entries == null || result.entries.length === 0) {
                return;
            }

            // Since we are retrieving entries for just one day we just need the first list of entries.
            let newEntries = result.entries[0];

            // If this day is already in our state.entries, we need to replace the old data with the new data.
            let dayIndex = this.state.entries.findIndex(el => {return (el._id.year === newEntries._id.year && el._id.month === newEntries._id.month && el._id.day === newEntries._id.day)});

            if(dayIndex !== -1) {
                this.state.entries[dayIndex] = newEntries;
            }
            else {
                this.state.entries.push(newEntries);
                this.state.entries.sort((el, el2) => {return (new Date(el2._id.year, el2._id.month, el2._id.day).getTime() - new Date(el._id.year, el._id.month, el._id.day).getTime())})
            }

            this.setStateDirty("entries");

        });
    }

    onMount() {
        // Load more time entries on scroll.
        window.onscroll = this.loadMoreData.bind(this);

        this.timeEntryPopout = this.getComponent("time-entry-popout");
    }

    async loadMoreData(event) {
        if (
            document.documentElement.scrollHeight -
                document.documentElement.scrollTop <=
                document.documentElement.clientHeight + 500 &&
            !this.state.loadingData && this.more
        ) {
            this.state.loadingData = true;
            this.getEntries();
        }
    }

    confirmDeleteEntry(entry, index, dayIndex) {
        emit("confirm", {
            title: "Delete Entry?",
            message:
                "Are you sure you want to delete the entry for '" +
                entry.task.name +
                "' on " +
                new Date(entry.start).toDateString() +
                "? This cannot be undone.",
            actions: [
                {
                    text: "Yes",
                    icon: "check",
                    callback: () => {
                        this.deleteEntry(entry, index, dayIndex);
                        this.state.disabled.push(entry._id);
                        this.setStateDirty("disabled");
                    },
                },
                {
                    text: "Cancel",
                    icon: "x",
                    callback: function () {},
                },
            ],
        });
    }

    async deleteEntry(entry, index, dayIndex) {
        ServerProxy.deleteEntry(entry._id, () => {
            this.state.entries[dayIndex].entries.splice(index, 1);

            if(this.state.entries[dayIndex].entries.length === 0) {
                this.state.entries.splice(dayIndex, 1);
            }

            this.setStateDirty("entries");

            emit("push", {
                title: "Success!",
                message:
                    "Time entry for " +
                    entry.task.name +
                    " on " +
                    new Date(entry.start).toDateString() +
                    " deleted.",
            });
        });
    }

    async editTimeEntry(id) {
        await this.timeEntryPopout.loadTimeEntry(id);
        this.timeEntryPopout.toggleVisibility();
    }

    async markTaskTodo(event) {
        let id = event.entry.taskid;
        let index = event.index;
        let response = await utils.post("/api/task/status", {
            userid: this.userid,
            taskid: id,
            status: "todo",
        });
        if (response.success) {
            for (let entry of this.input.entries) {
                if (entry.taskid === id) {
                    entry.status = "todo";
                }
            }
            this.state.entries = this.input.entries;
        }
    }

    async continueTask(taskid) {
        this.emit("continuetask", taskid);
    }
}
style {
    .duration-wrapper {
        align-items: flex-end;
    }
}

time-entry-popout key="time-entry-popout" on-saved("refresh")

ui-linear-layout#entries-wrapper vertical gutter="16px"
    for|day, dayIndex| of=state.entries || []
        @element [
            key=dayIndex
            style={
                animationName: "load",
                animationDuration: "0.5s",
                animationDelay: dayIndex * 0.1 + "s",
                animationFillMode: "both",
            }
        ]
            listwidget [
                title=niceDate(
                    (new Date(day._id.year, day._id.month - 1, day._id.day)).getTime()
                )
                subtitle=day.hoursTracked.toFixed(2) + "hrs"
                items=day.entries
            ]
                @item|{
                    data,
                    index,
                }| // Data here is a timeEntry from the items list.
                    swipeable left
                        ui-linear-layout [
                            aligncenter
                            wrap
                            paddedwide
                            fillspace
                            placecenter
                            filled
                            rounded
                        ]
                            @element fillspace
                                ui-linear-layout vertical gutter="0px"
                                    @element fillspace
                                        ui-text -- ${data.task.name}
                                    if(data.project)
                                        @element
                                            project project=data.project
                            @element
                                ui-linear-layout.duration-wrapper [
                                    vertical
                                    gutter="0px"
                                    alignright
                                ]
                                    @element
                                        ui-linear-layout
                                            @element
                                                ui-date [
                                                    date=false
                                                    milliseconds=(new Date(data.start)).getTime()
                                                ]
                                            @element
                                                ui-text -- -
                                            @element
                                                ui-date [
                                                    date=false
                                                    milliseconds=(new Date(data.end)).getTime()
                                                ]
                                    @element
                                        ui-emphasis
                                            ui-duration start=(new Date(data.start)).getTime() end=(new Date(data.end)).getTime()
                            @element fullwidth=input.ismobile alignright
                                $ {
                                    let controls = [
                                        { name: "Todo", icon: "flag" },
                                        { name: "Delete", icon: "trash" },
                                        { name: "Edit", icon: "edit-2" },
                                        { name: "Continue", icon: "play" },
                                    ];
                                }
                                controls [
                                    controls=controls
                                    on-continue("continueTask", data.task._id)
                                    on-delete(
                                        "confirmDeleteEntry",
                                        data,
                                        index,
                                        dayIndex,
                                    )
                                    on-edit("editTimeEntry", data._id)
                                ]
    if(state.loadingData)
        @element fullwidth aligncenter paddedwide light
            loader
