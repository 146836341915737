import ServerProxy from "../../../../utils/serverproxy.js";
import {dateInPast, now} from "../../../../utils/dateutils.js";

class {
    onCreate(input) {
        this.includedProjects = input.includedprojects||[];
        this.includedTasks = [];
        this.start = input.start||dateInPast(7);
        this.end = input.end||now();

        this.state = {
            data: input.data||{}
        };

        if(this.state.data.length === 0) {
            this.loadData();
        }
    }

    onInput(input) {
        this.includedProjects = input.includedprojects||[];
        this.start = input.start||dateInPast(7);
        this.end = input.end||now();

        if(input.data) {
            this.state.data = input.data;
            //this.setStateDirty("data");
        }
        else {
            this.loadData();
        }
    }

    loadData() {
        ServerProxy.generateReport(this.start, this.end, this.includedProjects, this.includedTasks, (report) => {
            this.state.data = report;
            this.setStateDirty("data");
        });
    }
}

style {
    .invoice-heading {
        font-weight: 100;
    }

    .invoice-expanded-column {
        flex: 2;
    }

    .invoice-column {
        flex: 1;
    }

    .invoice-row {
        background-color: var(--primary-dark);
    }

    .invoice-tasks-summary {
        font-size: 12px;
        margin-top: 0px !important;
        font-style: italic !important;
    }

    .invoice-chart-wrapper {
        overflow-x: auto;
    }

    .invoice-chart {
        min-width: 450px;
        box-shadow: none !important;
    }

    .invoice-chart .listwidget-first {
        background-color: var(--secondary);
        color: var(--text-light);
    }

    .invoice-chart-header {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .invoice-chart-row {
        background-color: var(--oc-gray-0);
    }
}

$ let items = Object.values(state.data.projects||{}).filter((project) => {return project.timeTracked > 0;});
div.invoice-chart-wrapper
    listwidget.invoice-chart vertical items=items
        @first
            ui-linear-layout.invoice-chart-header secondary paddedwide rounded
                @element.invoice-expanded-column
                    ui-heading.invoice-heading heading=3 -- Description
                @element fillspace alignright
                    ui-heading.invoice-heading heading=3 -- Hours
                @element fillspace alignright
                    ui-heading.invoice-heading heading=3 -- Rate
                @element fillspace alignright
                    ui-heading.invoice-heading heading=3 -- Total
        if(state.data == null)
            @last
                ui-text -- No time entries found for the selected date range and filters.
        @item|{data}|
            ui-linear-layout.invoice-chart-row paddedwide
                @element.invoice-expanded-column.invoice-row-description
                    ui-linear-layout vertical
                        @element
                            ui-text -- ${data.name}
                        @element.invoice-tasks-summary
                            ui-text -- ${Object.values(data.tasks||{}).map((el) => {return el.name}).join(', ')}
                @element.invoice-row-time fillspace alignright
                    ui-text -- ${data.hoursTracked.toFixed(2)}
                @element.invoice-row-rate fillspace alignright
                    ui-text -- ${parseFloat(data.wage||'0').toLocaleString('en-US', {style: "currency", currency: "USD"})}
                @element.invoice-row-tota fillspace alignright
                    ui-text --  ${parseFloat(data.earnings||'0').toLocaleString('en-US', {style: "currency", currency: "USD"})}