style {
    .paper-modal {
        background-color: white;
        filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.1));
        /*box-shadow: 0px 0px 10px rgba(0,0,0,0.1);*/
        position: relative;
        padding: 25px;
        margin-top: 40px !important;
        padding-top: 0px;
        border-radius: 0 0 var(--rounded-radius) var(--rounded-radius);
    }

    .paper-modal::before {
        position: absolute;
        top: -40px;
        left: 0px;
        width: calc(100% - 40px);
        height: 40px;
        background-color: white;
        content: "";
        display: block;
        border-radius: var(--rounded-radius) 0 0 0;
    }

    .paper-modal::after {
        position: absolute;
        top: -40px;
        right: 0px;
        display: block;
        border-style: solid;
        content: "";
        border-width: 0 40px 40px 0;
        border-style: solid;
        border-color: var(--border) transparent;
    }
}

div class=input.class id=input.id
    ui-container.paper-modal
        <${input.renderBody}/>
