style {
    .page-background {
        z-index: -1;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg, var(--tertiary), var(--secondary));
    }
}

div.page-background