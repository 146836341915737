style {
    .loader-wrapper {
        display: flex;
        flex: 1;
        width: 100%;
        place-content: center;
        align-items: center;
    }

    .loader {
        --loader-size: 40px;
        width: var(--loader-size);
        height: var(--loader-size);
        animation-name: loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        background-color: white;
    }

    @keyframes loader {
        0% {border-radius: 5px; transform: rotate(0deg);}
        50% {border-radius: calc(var(--loader-size) / 2); transform: rotate(90deg);}
        100% {border-radius: 5px; transform: rotate(180deg);}
    }
}

div.loader-wrapper
    div.loader